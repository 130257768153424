import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { InputState } from './SharedInputComponents';

export const getInputState = ({
  disabled,
  errorMessage,
}: {
  disabled: boolean | undefined;
  errorMessage: string | undefined;
}): InputState => {
  if (errorMessage) {
    return 'error';
  }

  if (disabled) {
    return 'disabled';
  }

  return 'default';
};

export const getInputIconRight = ({
  iconRight,
  state,
  warningIcon,
}: {
  iconRight: ImageWithConfigFragment | null | undefined;
  state: InputState;
  warningIcon: ImageWithConfigFragment | null | undefined;
}) => {
  if (iconRight) {
    return iconRight;
  }

  if (state === 'error') {
    return warningIcon;
  }

  return null;
};

export const getInputIconLeft = ({
  iconLeft,
}: {
  iconLeft: ImageWithConfigFragment | null | undefined;
}) => {
  if (iconLeft) {
    return iconLeft;
  }

  return null;
};
