import { useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  AvailabilityNode,
  useGetAvailabilityQuery,
} from '@codegen/gatewayUtils';
import { isSameDay, parseDateString } from '@utils/dateUtils';
import {
  getClosestAvailableDate,
  getClosestAvailableRountrip,
} from '@web/utils/search/searchWidgetUtils';
import { useConstants } from '../ConstantContext';
import { useSettings } from '../SettingsContext';

const useGetAvailability = ({
  destinations,
  origins,
}: {
  destinations: string[];
  origins: string[];
}) => {
  const { partner } = useConstants();
  const { currency } = useSettings();
  const { data, isLoading, isPlaceholderData } = useGetAvailabilityQuery(
    {
      partner,
      currencyCode: currency,
      origins,
      destinations,
    },
    {
      enabled: Boolean(origins.length > 0 && destinations.length > 0),
      placeholderData: { availability: { homebound: [], outbound: [] } },
      retry: 2,
    },
  );

  const { homebound: arrivalDays, outbound: departureDays } =
    data?.availability || {
      homebound: [] as AvailabilityNode[],
      outbound: [] as AvailabilityNode[],
    };

  const checkIfArrivalDateIsDisabled = useCallback(
    (date: Dayjs, departureDate?: Maybe<Dayjs>) => {
      if (departureDate && date.isBefore(departureDate)) {
        return true;
      }

      return (
        !arrivalDays.some(
          (day) => day?.date && isSameDay(parseDateString(day.date), date),
        ) && !isPlaceholderData
      );
    },
    [arrivalDays, isPlaceholderData],
  );

  const checkIfDepartureDateIsDisabled = useCallback(
    (date: Dayjs) =>
      !departureDays.some(
        (day) => day?.date && isSameDay(parseDateString(day.date), date),
      ) && !isPlaceholderData,
    [departureDays, isPlaceholderData],
  );

  const getAlternativeAvailableDate = useCallback(
    ({
      departureDateString,
      returnDateString,
    }: {
      departureDateString?: string;
      returnDateString?: string;
    }) => {
      if (departureDateString && returnDateString) {
        return getClosestAvailableRountrip({
          availableDepatureDays: departureDays,
          departureDate: dayjs(departureDateString),
          returnDate: dayjs(returnDateString),
          checkIfArrivalDateIsDisabled,
        });
      } else if (departureDateString) {
        const firstClosestDepartureDate = getClosestAvailableDate(
          dayjs(departureDateString),
          departureDays,
        );

        const secondClosestDepartureDate = getClosestAvailableDate(
          dayjs(firstClosestDepartureDate),
          departureDays,
        );

        return [
          ...(firstClosestDepartureDate
            ? [
                {
                  departureDate: firstClosestDepartureDate,
                  returnDate: null,
                },
              ]
            : []),
          ...(secondClosestDepartureDate
            ? [
                {
                  departureDate: secondClosestDepartureDate,
                  returnDate: null,
                },
              ]
            : []),
        ];
      }

      return [];
    },
    [checkIfArrivalDateIsDisabled, departureDays],
  );

  return {
    checkIfArrivalDateIsDisabled,
    checkIfDepartureDateIsDisabled,
    getAlternativeAvailableDate,
    arrivalDays: arrivalDays as AvailabilityNode[],
    departureDays: departureDays as AvailabilityNode[],
    isNoDateAvailable: departureDays.length === 0 && !isPlaceholderData,
    isLoading,
    // If we have no arrivalDates but departureDays the only option for the user is to do a oneway trip
    isForceOneWay:
      arrivalDays.length === 0 &&
      !isPlaceholderData &&
      departureDays.length > 0,
  };
};

export default useGetAvailability;
