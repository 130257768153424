import useTranslation from 'next-translate/useTranslation';
import Box from '@ui-v2/core/Box/Box';
import Button from '@web/core/Button/Button';
import Input from '@web/core/Forms/Input/Input';
import Modal from '@web/core/Modal/Modal';
import {
  isDecrementPassengerDisabled,
  isIncrementPassengerDisabled,
} from '../helpers';
import PassengerCounterItem from '../PassengerCounterItem';
import { usePassengerCounter } from '../usePassengerCounter';

export type Passengers = Record<string, number>;

const PassengerCounterMobile = () => {
  const {
    handleChangePassengerAge,
    handleSetPassengers,
    handleSetSearchState,
    isOpen,
    numberOfSelectedPassengers,
    openState,
    passengerRules,
    passengers,
    searchState,
  } = usePassengerCounter();

  const { t } = useTranslation();

  const placeholder = `1 ${t('Passenger').toLocaleLowerCase()}`;
  const value =
    searchState === 'selecting-passengers' || openState === 'opened'
      ? `${numberOfSelectedPassengers} ${
          numberOfSelectedPassengers > 1
            ? t('Passengers').toLocaleLowerCase()
            : t('Passenger').toLocaleLowerCase()
        }`
      : '';

  return (
    <Box>
      <Input
        iconLeft="passengerIcon"
        label={t('Passengers')}
        name="passengers"
        onFocus={handleSetSearchState.bind(null, 'selecting-passengers')}
        overriddenActiveState={
          searchState === 'selecting-passengers' || openState === 'opened'
        }
        overriddenFocusState={searchState === 'selecting-passengers'}
        placeholder={placeholder}
        readOnly
        value={value}
        variant="floating-label"
      />
      <Modal
        footer={
          <Box display="flex" justifyContent="space-between" mt="auto">
            <Button
              onClick={handleSetSearchState.bind(null, 'idle')}
              size="small"
              type="button"
              variant="secondary"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={handleSetSearchState.bind(null, 'idle')}
              size="small"
              type="button"
            >
              {t('Confirm')}
            </Button>
          </Box>
        }
        header={
          <Box width="full">
            <Input
              iconLeft="passengerIcon"
              label={t('Passengers')}
              name="passengers"
              onFocus={handleSetSearchState.bind(null, 'selecting-passengers')}
              overriddenActiveState={false}
              overriddenFocusState={false}
              placeholder={placeholder}
              readOnly
              tabIndex={-1}
              value={value}
              variant="floating-label"
            />
          </Box>
        }
        hideCloseIcon
        id="mobile-passenger-counter-modal"
        isOpen={isOpen}
        mobileSize="full-screen"
        onOpenChange={handleSetSearchState.bind(null, 'idle')}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box>
            {passengerRules.rules.map((paxRule, index) => {
              const type = paxRule.passengerType;
              const paxValue = passengers[type] ?? [];

              return (
                <PassengerCounterItem
                  isDecrementDisabled={isDecrementPassengerDisabled({
                    maxPassengersAllowed: passengerRules.maxCount ?? Infinity,
                    numberOfSelectedPassengersOfType: paxValue.length,
                    numberOfTotalSelectedPassengers: numberOfSelectedPassengers,
                    passengers,
                    paxRule,
                    passengerRules,
                  })}
                  isIncrementDisabled={isIncrementPassengerDisabled({
                    maxPassengersAllowed: passengerRules.maxCount ?? Infinity,
                    numberOfSelectedPassengersOfType: paxValue.length,
                    numberOfTotalSelectedPassengers: numberOfSelectedPassengers,
                    passengers,
                    paxRule,
                  })}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onChangePaxAge={handleChangePassengerAge}
                  onDecrement={() =>
                    paxValue.length > 0
                      ? handleSetPassengers({
                          ...passengers,
                          [type]: paxValue.slice(0, -1),
                        })
                      : null
                  }
                  onIncrement={() =>
                    numberOfSelectedPassengers <
                    (passengerRules.maxCount ?? Infinity)
                      ? handleSetPassengers({
                          ...passengers,
                          [type]: [
                            ...(passengers[type] || []),
                            paxRule.maxAge ?? paxRule.minAge ?? 0,
                          ],
                        })
                      : null
                  }
                  rule={paxRule}
                  subtitle={
                    paxRule.minAge !== undefined && paxRule.minAge !== null
                      ? `${paxRule.minAge}${paxRule.minAge > 15 ? '+' : ''}${
                          paxRule.maxAge ? ` - ${paxRule.maxAge}` : ''
                        }`
                      : ''
                  }
                  title={paxRule.label.value}
                  value={paxValue}
                />
              );
            })}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PassengerCounterMobile;
