import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UseComboboxGetItemPropsOptions } from 'downshift';
import { TransportType } from '@codegen/offerAPI';
import Box from '@ui-v2/core/Box/Box';
import Text from '@ui-v2/core/Text/Text';
import { StationListNode } from '@web/context/SearchWidgetContext';
import Icon, { IconProps } from '@web/core/Icon/Icon';

const ItemBox = styled(Box)(() => [
  css`
    cursor: pointer;
    transition: background-color 100ms;
  `,
]);

const ItemContentBox = styled(Box)(() => [
  css`
    transition: color 100ms;

    img {
      transition: filter 100ms;
    }
  `,
]);

const transportTypeIconMapper: Record<TransportType, IconProps['type']> = {
  AIRPORT: 'flightOutboundIcon',
  TRAIN: 'trainIcon',
  AIRPORT_AND_TRAIN: 'trainAndPlaneIcon',
};

export type StationPickerItemProps =
  UseComboboxGetItemPropsOptions<StationListNode> & {
    isHighlighted: boolean;
    item: StationListNode;
    level: 1 | 2;
  };

const StationPickerItem = forwardRef<HTMLDivElement, StationPickerItemProps>(
  ({ isHighlighted, item, level, ...props }, forwardedRef) => {
    return (
      <ItemBox
        alignItems="center"
        borderBottom="subdued"
        display="flex"
        {...props}
        as="li"
        bg={isHighlighted ? 'surface.selected.hover' : 'surface.main'}
        gap={16}
        pl={level === 2 ? 24 : 16}
        pr={16}
        py={level === 2 ? 4 : 8}
        ref={forwardedRef}
        width="auto"
      >
        <Icon
          colour="icons.default"
          height={18}
          type={
            item.transportType
              ? transportTypeIconMapper[item.transportType as TransportType]
              : 'defaultPassportIcon'
          }
          width={18}
        />
        <ItemContentBox colour="components.date.text.default">
          <Text as="div" variant="body-1">
            {item.value}
          </Text>
          <Text as="div" variant="body-3">
            {item.country}
          </Text>
        </ItemContentBox>
      </ItemBox>
    );
  },
);

export default StationPickerItem;
