import { PassengerRulesFragment } from '@codegen/cmsUtils';
import {
  constructSearchQueryParams,
  getSearchQueryParamsFromLocalStorage,
} from '@web/utils/search/searchWidgetUtils';
import usePartnerRouter from './usePartnerRouter';

const useSearchQueryParamsWithLocalStorage = (
  passengerRules: PassengerRulesFragment | null | undefined,
) => {
  const { query } = usePartnerRouter();
  const localstorageParams =
    getSearchQueryParamsFromLocalStorage(passengerRules);

  const {
    currency,
    departureDate,
    destinations,
    isOneWay,
    origins,
    paxTypeAges,
    residency,
    returnDate,
  } = constructSearchQueryParams(
    {
      ...query,
      origins: Array.isArray(query.origins)
        ? query.origins
        : query.origins?.split(','),
      destinations: Array.isArray(query.destinations)
        ? query.destinations
        : query.destinations?.split(','),
    },
    passengerRules,
    localstorageParams,
  );

  return {
    origins,
    destinations,
    departureDate,
    returnDate,
    residency,
    currency,
    isOneWay,
    paxTypeAges,
  };
};

export default useSearchQueryParamsWithLocalStorage;
