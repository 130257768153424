import { SearchQueryParams } from '@web/types/searchWidgetTypes';
import { Passengers, Station, StationListNode } from '../SearchWidgetContext';

export const saveDateRangeToLocaleStorage = (dateRange: DateRange) => {
  if (dateRange.from) {
    localStorage.setItem(
      SearchQueryParams.DEPARTURE_DATE,
      dateRange.from.toString(),
    );
  } else {
    localStorage.removeItem(SearchQueryParams.DEPARTURE_DATE);
  }

  if (dateRange.to) {
    localStorage.setItem(
      SearchQueryParams.RETURN_DATE,
      dateRange.to.toString(),
    );
  } else {
    localStorage.removeItem(SearchQueryParams.RETURN_DATE);
  }
};

export const savePassengersToLocalStorage = (passengers: Passengers) => {
  Object.keys(passengers).forEach((key) => {
    localStorage.setItem(key, passengers[key]?.join(',') ?? '');
  });
};

export const arrangeStationsToListNodes = (
  items: Station[],
): StationListNode[] =>
  items.flatMap((item) =>
    item.subItems.flatMap((subItem) => [
      {
        ...subItem,
        country: item.value,
        parentCode: null,
      },
      ...subItem.subItems.flatMap((subSubItem) => ({
        ...subSubItem,
        parentCode: subItem.code,
        country: item.value,
      })),
    ]),
  );

export const findStationListNodeBySearchQuery = ({
  items,
  queryCodes,
}: {
  items: StationListNode[];
  queryCodes: string[] | undefined;
}): StationListNode | null => {
  if (!queryCodes || queryCodes.length === 0) {
    return null;
  }

  // Single value, find that value
  if (queryCodes.length === 1) {
    const [queryCode] = queryCodes;

    return items.find((x) => x.code === queryCode) ?? null;
  }

  // If a parent item includes every code in the query, we return the parent item
  const parentItem = items.find((item) => {
    return (
      item.subItems.length > 0 &&
      item.subItems.every((subItem) => queryCodes.includes(subItem.code))
    );
  });

  if (parentItem) {
    return parentItem;
  }

  // No parent match, try to match an item with any query code
  return items.find((x) => queryCodes.includes(x.code)) ?? null;
};

export const getStationCodes = (station: StationListNode) => {
  // If this is a parent item, return a map of the child codes
  if (station.subItems.length > 0) {
    return station.subItems.map((x) => x.code);
  }

  // Return items code as an array
  return [station.code];
};
