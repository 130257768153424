import { useCallback, useState } from 'react';
import { FilterLimit, Limit, OfferFilters } from '@codegen/gatewayUtils';
import { defaultFilters } from '@ui/components/Filters/constants';

export const useSearchFilters = () => {
  const [activeFilters, setActiveFilters] = useState<OfferFilters | null>(null);

  // Active filters represents the current selected filters
  // Offers filters is the filter range that we get from the gateway
  // defaultFilters are only used as a fallback, that case should never happen
  const filters = activeFilters || defaultFilters;

  // Setters
  const onToggleOvernightStay = useCallback(
    () =>
      setActiveFilters({
        ...filters,
        overnightStay: !filters.overnightStay,
      }),
    [filters],
  );

  const onToggleOvernightFlight = useCallback(
    () =>
      setActiveFilters({
        ...filters,
        overnightFlight: !filters.overnightFlight,
      }),
    [filters],
  );

  const setConnectionTimeValue = useCallback(
    (value: Limit) =>
      setActiveFilters({
        ...filters,
        connectionTime: value,
      }),
    [filters],
  );

  const setTakeOffValue = useCallback(
    (value: FilterLimit) => {
      return setActiveFilters({
        ...filters,
        takeoff: value,
      });
    },
    [filters],
  );

  const setLandingValue = useCallback(
    (value: FilterLimit) =>
      setActiveFilters({
        ...filters,
        landing: value,
      }),
    [filters],
  );

  const onSetMaxNumberOfStops = useCallback(
    (value: string | null) =>
      setActiveFilters({
        ...filters,
        maxNumberOfStops: value ? Number(value) : null,
      }),
    [filters],
  );

  const resetFilters = useCallback(() => setActiveFilters(null), []);

  return {
    activeFilters,
    onToggleOvernightStay,
    onToggleOvernightFlight,
    setConnectionTimeValue,
    setTakeOffValue,
    setLandingValue,
    setActiveFilters,
    resetFilters,
    onSetMaxNumberOfStops,
  };
};
