import { forwardRef } from 'react';
import SelectBase, {
  SelectProps as SelectBaseProps,
} from '@ui-v2/core/Forms/Select/Select';
import { IconType } from '@ui-v2/types/typography';
import useGetIcon from '@web/hooks/useGetIcon';

export type SelectProps = Omit<
  SelectBaseProps,
  'iconLeft' | 'iconRight' | 'arrowIcon'
> & {
  iconLeft?: IconType;
  iconRight?: IconType;
};

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ iconLeft, iconRight, ...props }: SelectProps, forwardedRef) => {
    const getIcon = useGetIcon();

    return (
      <SelectBase
        {...props}
        arrowIcon={getIcon('chevronDownIcon')}
        iconLeft={iconLeft ? getIcon(iconLeft) : null}
        iconRight={iconRight ? getIcon(iconRight) : null}
        ref={forwardedRef}
      />
    );
  },
);

export default Select;
