import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useGetStationsQuery } from '@codegen/gatewayUtils';
import {
  constructItems,
  findItemParentByCode,
} from '@web/utils/search/searchWidgetUtils';
import { useConstants } from './../ConstantContext';

const useGetStations = ({
  destinations = [],
  origins = [],
}: {
  destinations?: string[];
  origins?: string[];
}) => {
  const { t } = useTranslation();

  const { locale, partner } = useConstants();
  const { data: originData, isLoading: isOriginStationsLoading } =
    useGetStationsQuery(
      {
        partner,
        language: locale,
        search: '',
      },
      { retry: 2 },
    );

  const {
    data: destinationData,
    fetchStatus: destinationFetchStatus,
    isLoading: isDestinationStationsLoading,
  } = useGetStationsQuery(
    {
      partner,
      language: locale,
      search: '',
      origins,
    },
    { retry: 2, enabled: origins.length > 0 },
  );

  const originStations = useMemo(
    () => (originData ? constructItems({ data: originData, t }) : []),
    [originData, t],
  );

  const destinationStations = useMemo(
    () =>
      destinationData && destinationData.stations.length > 0
        ? constructItems({ data: destinationData, t })
        : [],
    [destinationData, t],
  );

  const selectedOriginItem =
    origins.length > 0
      ? findItemParentByCode(originStations, origins)
      : undefined;

  const selectedDestinationItem =
    destinations.length > 0
      ? findItemParentByCode(destinationStations, destinations)
      : undefined;

  return {
    originStations,
    destinationStations,
    selectedOriginItem,
    selectedDestinationItem,
    isOriginStationsLoading,
    isDestinationStationsLoading:
      isDestinationStationsLoading && destinationFetchStatus !== 'idle',
  };
};

export default useGetStations;
