import useTranslation from 'next-translate/useTranslation';
import Box from '@ui-v2/core/Box/Box';
import TabButton from '@ui-v2/core/TabButton/TabButton';
import {
  SearchWidgetProvider,
  SearchWidgetProviderProps,
  useSearchWidget,
} from '@web/context/SearchWidgetContext';
import Button from '@web/core/Button/Button';
import DatePicker from './components/DatePicker/DatePicker';
import MobileDatePicker from './components/DatePicker/mobile/MobileDatePicker';
import PassengerCounterMobile from './components/PassengerCounter/mobile/PassengerCounterMobile';
import PassengerCounter from './components/PassengerCounter/PassengerCounter';
import { SearchWidgetButton } from './components/SearchWidgetButton';
import MobileStationPicker from './components/StationPicker/mobile/MobileStationPicker';
import StationPicker from './components/StationPicker/StationPicker';
import { StationSwitcherWrapperBox } from './SearchWidget.styles';

export const SearchWidget = () => {
  const {
    activeTrip,
    destinationInputRef,
    destinationListNodes,
    handleDestinationSelection,
    handleOriginSelection,
    handleSearchSubmit,
    handleSetSearchState,
    handleSetTrip,
    handleSwitchStations,
    isMobile,
    originInputRef,
    originListNodes,
    searchState,
    stations,
  } = useSearchWidget();

  const handleSearchSubmitFocus = () => {
    handleSetSearchState('idle');
  };

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSearchSubmit}>
      <Box>
        <Box display="flex" gap={12} mb={4}>
          <TabButton
            isActive={activeTrip === 'round-trip'}
            onClick={handleSetTrip.bind(null, 'round-trip')}
            type="button"
          >
            {t('Round trip')}
          </TabButton>
          <TabButton
            isActive={activeTrip === 'one-way'}
            onClick={handleSetTrip.bind(null, 'one-way')}
            type="button"
          >
            {t('One way')}
          </TabButton>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection={['column', 'column', 'column', 'row']}
          gap={16}
          position="relative"
        >
          <Box
            display="flex"
            flexDirection={['column', 'column', 'row', 'row']}
            gap={[4, 4, 16]}
            position="relative"
            width={['full', 'full', 'full', '5/12']}
          >
            {isMobile ? (
              <>
                <Box width={['full', 'full', '1/2', '1/2', 'full']}>
                  <MobileStationPicker
                    iconLeft="flightOutboundIcon"
                    items={originListNodes}
                    name="airport-take-off"
                    onSelection={handleOriginSelection}
                    pickerType="selecting-take-off-station"
                    placeholder={t('From')}
                    ref={originInputRef}
                    selection={stations.origin}
                  />
                </Box>

                <Box
                  borderTop="subdued"
                  display={['none', 'none', 'none', 'none', 'block']}
                />

                <Box width={['full', 'full', '1/2', '1/2', 'full']}>
                  <MobileStationPicker
                    iconLeft="flightInboundIcon"
                    items={destinationListNodes}
                    name="airport-destination"
                    onSelection={handleDestinationSelection}
                    pickerType="selecting-destination-station"
                    placeholder={t('To')}
                    ref={destinationInputRef}
                    selection={stations.destination}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box width={['full', '1/2', '1/2', '1/2']}>
                  <StationPicker
                    iconLeft="flightOutboundIcon"
                    items={originListNodes}
                    name="airport-take-off"
                    onSelection={handleOriginSelection}
                    pickerType="selecting-take-off-station"
                    placeholder={t('From')}
                    ref={originInputRef}
                    selection={stations.origin}
                  />
                </Box>
                <Box width={['full', '1/2', '1/2', '1/2']}>
                  <StationPicker
                    iconLeft="flightInboundIcon"
                    items={destinationListNodes}
                    name="airport-destination"
                    onSelection={handleDestinationSelection}
                    pickerType="selecting-destination-station"
                    placeholder={t('To')}
                    ref={destinationInputRef}
                    selection={stations.destination}
                  />
                </Box>
              </>
            )}

            <StationSwitcherWrapperBox position="absolute">
              <Button
                aria-label={t('switch_origin_and_destination')}
                icon="roundTripIcon"
                isRounded
                onClick={handleSwitchStations}
                size="iconSmall"
                tabIndex={-1}
                type="button"
                variant="secondary"
              />
            </StationSwitcherWrapperBox>
          </Box>
          <Box width={['full', 'full', 'full', '4/12']}>
            {isMobile ? <MobileDatePicker /> : <DatePicker />}
          </Box>
          <Box width={['full', 'full', 'full', '2/12']}>
            {isMobile ? <PassengerCounterMobile /> : <PassengerCounter />}
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={['full', 'full', 'full', 'auto']}
          >
            <SearchWidgetButton
              data-cy="search-widget-submit"
              isLoading={searchState === 'routing'}
              ml="auto"
              onFocus={handleSearchSubmitFocus}
            >
              {t('Search')}
            </SearchWidgetButton>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

const SearchWidgetWithProvider = ({
  config,
}: {
  config: SearchWidgetProviderProps['config'];
}) => (
  <SearchWidgetProvider config={config}>
    <SearchWidget />
  </SearchWidgetProvider>
);

export default SearchWidgetWithProvider;
